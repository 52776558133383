import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { slide as Menu } from "react-burger-menu";

import "../sass/style.scss";
import "../sass/header.scss";

const Header = (data) => {
  return (
    <>
      <header role="menu">
        <h1 className="logo">
          <Link to={"/"}>
            <StaticImage
              layout="constrained"
              alt="OBATAROU-LOGO"
              src="../images/common/logo.svg"
              className="pc"
            />
            <StaticImage
              layout="constrained"
              alt="OBATAROU-LOGO"
              src="../images/common/logo-sp.svg"
              className="sp"
            />
          </Link>
        </h1>
        <div className="wrap pc">
          <Link className="menu-item store-info" to="/bakerycafe/">
            <h2>ヒヨリベーカリー＆カフェ</h2>
            <p className="t-left">9:00-18:00（L.O.17:30）</p><br />
            <p className="t-left">- 当面の間イルミネーション点灯特別営業時間となります。<br />
              - 天候により閉店時間が早まる場合がございます。当日の営業時間については店舗にお電話下さい。<br />
              - 店頭イルミネーションは22時まで点灯しております。</p>
          </Link>
          <Link className="menu-item store-info" to="/hiyoritei/">
            <h2>日和亭</h2>
            <p className="space-between">
              <span>ランチ</span>
              <span>11:00-15:00（L.O.14:30）</span>
            </p><br />
            <p className="flex-start">【ご宴会】</p>
            <p className="t-left">10名様以上の貸し切りご宴会を承っております。詳しくは店舗にお電話下さい。</p>
            {/* <p className="space-between">
              <span>ディナー</span>
              <span>
                <span className="red">＜前日までのご予約制＞</span>
                <br />
                17:00-21:00（L.O.20:00）
              </span>
            </p> */}
          </Link>
          <Link className="menu-item store-info" to="/access/">
            <h2>アクセス</h2>
          </Link>
          <Link className="menu-item store-info" to="/history/">
            <h2>割烹小幡の歴史</h2>
          </Link>
          <div className="menu-item store-info">
              {/* <p className="t-left">
                <span>【休業日】<br />2024/1/9(火)、1/10(水)、1/11(木)</span><br />
                <span>【休業日】 不定休</span>
              </p> */}
            <div className="newyear t-left">
              <h2 className="mb10">【年末年始営業】</h2>
              <div className="mb40">
                <h3>■ヒヨリベーカリー＆カフェ</h3>
                - 12月30日 9～15時 LO14時半<br />
                - 12月31日 休館日<br />
                - 1月1日 9～15時 LO14時半<br />
                - 2日より通常営業<br />
                ※1月14日(火),15日(水) 休館日<br />
              </div>
              <div>
                <h3>■日和亭</h3>
                - 12月30日 11～15時 LO14時半<br />
                - 12月31日 休館日<br />
                - 1月1日 休館日<br />
                - 1月2日〜 通常営業<br />
                ※1月14日(火),15日(水) 休館日<br />
              </div>
            </div>
          </div>
          <div className="menu-item tel">
            <p>TEL 0234-25-5729</p>
          </div>
          <div className="sns">
            <a href="https://www.instagram.com/hiyoriyama_obatarou/">
              <StaticImage
                layout="constrained"
                alt="Instagramアイコン"
                src="../images/common/icon_insta.svg"
              />
            </a>
          </div>
        </div>
        <div className="sp">
          <Menu
            right
            width={"90%"}
            customBurgerIcon={
              <img src="/images/hamburger.svg" alt="メニューボタン" />
            }
            customCrossIcon={<img src="/images/close.svg" alt="閉じるボタン" />}
            className="wrap"
          >
            <Link className="menu-item store-info" to={"/"}>
              <h2 className="main-color">
                <span>日和山 小幡楼</span>
              </h2>
            </Link>
            <Link className="menu-item store-info" to="/bakerycafe/">
              <h2>ヒヨリベーカリー＆カフェ</h2>
              <p>9:00-18:00（L.O.17:30）</p>
              <p>- 当面の間イルミネーション点灯特別営業時間となります。<br />
                - 天候により閉店時間が早まる場合がございます。当日の営業時間については店舗にお電話下さい。<br />
                - 店頭イルミネーションは22時まで点灯しております。</p>
            </Link>
            <Link className="menu-item store-info" to="/hiyoritei/">
              <h2>日和亭</h2>
              <p className="space-between mb20">
                <span>ランチ</span>
                <span>11:00-15:00（L.O.14:30）</span>
              </p>
              <p className="t-center">【ご宴会】</p>
              <p className="t-left">10名様以上の貸し切りご宴会を承っております。詳しくは店舗にお電話下さい。</p>
              {/* <p className="space-between">
                <span>ディナー</span>
                <span>
                  <span className="red">＜前日までのご予約制＞</span>
                  <br />
                  17:00-21:00（L.O.20:00）
                </span>
              </p> */}
            </Link>
            <Link className="menu-item store-info" to="/access/">
              <h2>アクセス</h2>
            </Link>
            <Link className="menu-item store-info" to="/history/">
              <h2>割烹小幡の歴史</h2>
            </Link>
            <div className="menu-item store-info">
              <p className="t-left">
                {/* <span>【休業日】<br />2024/1/9(火)、1/10(水)、1/11(木)</span><br /> */}
                <span>【休業日】 不定休</span>
              </p><br />
              {/* <p className="t-left">
                <span>【年末年始営業】<br />
                  12月30日:通常営業<br />
                  12月31日:時短営業<br />
                  - 9:00～15:00 LO14:30、日和亭:休業<br />
                  1月1日:時短営業<br />
                  - 9:00～15:00 LO14:30、日和亭:休業<br />
                  1月2日:通常営業</span>
              </p> */}
            </div>
            <div className="menu-item tel">
              <a href="tel:0234255729">TEL 0234-25-5729</a>
            </div>
            <div className="sns">
              <a
                href="https://www.instagram.com/hiyoriyama_obatarou/"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  layout="constrained"
                  alt="Instagramアイコン"
                  src="../images/common/icon_insta.svg"
                />
              </a>
            </div>
          </Menu>
        </div>
      </header>
    </>
  );
};

export default Header;
